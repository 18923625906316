import Vue from 'vue'
import Vuex from 'vuex'
import session from '../session'
// import LocalStorage from "../storage"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: null,
    userInfo: {},
    selectedRegion: '<Maak uw keuze>',
    selectedLevel: 'zorgkantoor',
    selectedYear: 2021
  },
  mutations: {
    setLogin(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setUser(state, data) {
      state.userInfo = data;
    },
    changeRegion (state, newRegion) {
      state.selectedRegion = newRegion
    },
    changeLevel (state, newLevel) {
      state.selectedLevel = newLevel
    },
    changeYear (state, newYear) {
      state.selectedYear = newYear
    }
  },
  actions: {
    checkLogin ({ commit }, vm) {
      session.get('/rest-auth/user/').then(
        (response) => {
          commit('setLogin', true)
          commit('setUser', response.data)

          if (vm.$route.name === "ZSD-Login") {
            if (vm.userInfo.is_regiocoordinator) {
              vm.$router.push('/zorgstandaard/Netwerkcoordinator/')
            } else {
              vm.$router.push('/zorgstandaard/netwerkanalyse')
            }
          }
        },
        () => {
          commit('setLogin', false)
          if (['Zorgstandaard-regiocoordinator'].indexOf(vm.$route.name) !== -1) {
            vm.$router.push('/login').catch(() => {})
          }
        }
      )
    }
  },
  modules: {
  },
  getters: {
    loggedIn: state => {
      return state.loggedIn;
    },
    userInfo: state => {
      return state.userInfo;
    },
    selectedRegion: state => {
      return state.selectedRegion;
    },
    selectedLevel: state => {
      return state.selectedLevel;
    },
    selectedYear: state => {
      return state.selectedYear;
    }
  }
})
