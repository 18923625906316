<template>
  <section class="footer-section">
    <div class="container">
      <div class="row">
        <div class="footer-contact">
          <font-awesome-icon class="contact-icon" icon="envelope" size="1x" /><br>
          <h2 class="contact-title">Vragen of opmerkingen?</h2>
          <p class="contact-text">Dit dashboard is in ontwikkeling en wordt periodiek bijgewerkt. Wij proberen u van zo goed mogelijke informatie te voorzien. Heeft u vragen, opmerkingen, toevoegingen of onjuistheden die van toepassing zijn op dit dashboard, stuur dan een bericht naar <a class="custom-link" aria-label="Email dementiedata@amsterdamdatacollective.com" href="mailto:dementiedata@amsterdamdatacollective.com">dementiedata@amsterdamdatacollective.com</a></p>
          <p class="email-text-start" >Wil je een update ontvangen als er nieuwe data is toegevoegd, vul dan hier uw emailadres in.</p>
          <button @click="showAddEmailModal" class="btn custom-button">Emailadres invullen</button>
          <add-email-modal v-show="isAddEmailModalVisible" @close="closeModal"/>
          <p class="email-text-end" ></p>
        </div>
      </div>

      <div class="row align-items-start justify-content-center">
        <div class="col-sm">
          <h5 class="footer-route-headers">Levenspad</h5>
        </div>
        <div class="col-sm">
          <h5 class="footer-route-headers">Thema's</h5>
        </div>
        <div class="col-sm">
          <h5 class="footer-route-headers">Zelfscan ZSD</h5>
        </div>
      </div>

      <div class="row align-items-start justify-content-center" style="margin-top: 10px; margin-bottom: 60px;">
        <div class="col-sm">
          <a class="footer-link" aria-label="Link naar Niet-Pluis" href="/Niet-Pluis/7">Niet-Pluis</a><br>
          <a class="footer-link" aria-label="Link naar Diagnose" href="/Diagnose/7">Diagnose</a><br>
          <a class="footer-link" aria-label="Link naar Leven met dementie" href="/Leven-Met-Dementie/7">Leven met dementie</a><br>
          <a class="footer-link" aria-label="Link naar Stervensfase en Nazorg" href="/Nazorg/7">Stervensfase en Nazorg</a><br>
        </div>
        <div class="col-sm">
          <a class="footer-link" aria-label="Link naar Achtergrondkenmerken" href="/Factsheet/1">Achtergrondkenmerken</a><br>
          <a class="footer-link" aria-label="Link naar Langdurige Zorg" href="/Factsheet/2">Langdurige Zorg</a><br>
          <a class="footer-link" aria-label="Link naar Ziekenhuisopnames" href="/Factsheet/3">Ziekenhuisopnames</a><br>
          <a class="footer-link" aria-label="Link naar Maatschappelijke Ondersteuning" href="/Factsheet/4">Maatschappelijke Ondersteuning</a><br>
          <a class="footer-link" aria-label="Link naar Medicatie" href="/Factsheet/5">Medicatie</a><br>
          <a class="footer-link" aria-label="Link naar Huisarts" href="/Factsheet/6">Huisarts</a><br>
          <a class="footer-link" aria-label="Link naar Zorg door de tijd" href="/Factsheet/7">Zorg door de tijd</a><br>
          <a class="footer-link" aria-label="Link naar Mantelzorg" href="/Factsheet/7">Mantelzorg</a><br>
        </div>
        <div class="col-sm">
          <a class="footer-link" aria-label="Link naar Inlogportaal" href="/login">Inlogportaal</a><br>
          <a class="footer-link" aria-label="Link naar Quickscan resultaten" href="/zorgstandaard/resultaten-quickscan">Quickscan resultaten</a><br>
        </div>
      </div>


      <div class="row align-items-center justify-content-center">
        <div class="col-sm">
          <h5><a class="footer-route-headers hover-link" aria-label="Link naar Identificatie" href="/Identificatie">Identificatie</a><br></h5>
        </div>
        <div class="col-sm">
          <h5><a class="footer-route-headers hover-link" aria-label="Link naar Overzicht" href="/Overzicht">Overzicht</a><br></h5>
        </div>
        <div class="col-sm">
          <h5><a class="footer-route-headers hover-link" aria-label="Link naar Help" href="/Help">Help</a><br></h5>
        </div>
      </div>
    </div>



    <div class="d-flex flex-row justify-content-center align-items-center footer-logos">
      <div class="w-75">
        <div class="row justify-content-between">
          <div class="col-sm-6 center">
            <img :src="adclogo" class="header-logo" height="60vh"/>
          </div>
          <div class="col-sm-6 center">
            <img :src="erasmuslogo" class="header-logo" height="60vh"/>
          </div>      
        </div>
      </div>
    </div>


  </section>
</template>

<script>

import AddEmailModal from '../components/AddEmailModal.vue'

export default {
  props: {
    adclogo: String,
    erasmuslogo: String,
  },
  components: { AddEmailModal},
  data() {
    return {
      isAddEmailModalVisible: false
    }
  },
  methods: {
    showAddEmailModal() {
      this.isAddEmailModalVisible = true;
    },
    closeModal() {
      this.isAddEmailModalVisible = false;
    },
  }
};
</script>

<style scoped>

p{
  font-weight: lighter;
  margin: 30px 0 30px 0;
  font-size: 16px;
}


h2,
h5{
  font-weight: bold;
}

h6{
  font-weight: thin;
}

a:link,
a:visited,
a:hover,
a:focus {
  text-decoration: none;
}

.footer-section{
  padding: 100px 100px 100px 100px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: var(--color-9);

 }

.footer-route-headers{
  color: var(--color-12);
  font-weight: bold;
  /*text-align: center;*/
}

.footer-route-subheaders{
  color: var(--color-12);
  text-align: left;
}


.footer-logos{
  margin-top: 150px;
}


/*.footer-contact{
  color: var(--color-12);
}

.footer-title{
  color: var(--color-12);
}*/

.contact-icon{
  font-size: 2em;
  margin-right: 30px;
}

.contact-title{
  font-weight: bold;
  width: 500px;
  font-size: 3em;
}

.contact-text{
  line-height: 1.6;
  padding: 1rem 0 0 0;
}

.email-text-start{
  line-height: 1.6;
  padding: 0 0 0 0;
}

.email-text-end{
  line-height: 1.6;
  padding: 0 0 4rem 0;
}

ul {
  list-style-type: none;
}

.hover-link{
  font-size: 20px;
  color: var(--color-12);
  transition: font-size 0.3s ease !important;
}

.hover-link:hover,
.hover-link:focus {
  outline: none;
  box-shadow: none;
  color: var(--color-11);
  font-size: 22px;
}


.footer-link {
  font-weight: lighter;
  margin: 30px 0 30px 0;
  font-size: 16px;
  color: var(--color-12);
  transition: font-size 0.3s ease !important;
}

.footer-link:hover {
  color: var(--color-11);
  font-size: 18px;
}

.footer-link:focus{
  outline: none;
  box-shadow: none;
  color: var(--color-11);
  font-size: 18px;
} 


.custom-link {
  color: var(--color-12);
  background-color: none;
  display: inline-block;
  margin-right: 0px !important;
  outline: none;
  font-weight: bold;
  font-size: 15px;
  padding: 3px 3px 3px;
  text-decoration: underline !important;
  transition: all .3s ease-out;
}


.custom-link:hover {
  color: var(--color-11);
  outline: none;
  box-shadow: none;
  font-size: 18px;
}

.custom-link:focus{
  outline: none;
  box-shadow: none;
  color: var(--color-11);
  font-size: 18px;
} 

.custom-button {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: #403932;
  border: none;
  outline: none;
  font-weight: bold;
  border-radius: 20px;
}

.custom-button:hover {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: var(--color-1);
  border: none;
  outline: none;
}

.custom-button:focus {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-1);
  background: var(--color-2);
  border: 2px var(--color-1) solid !important;
  outline: none;
}
</style>