import Vue from 'vue'
import App from './App2.vue'
import VueGtag from 'vue-gtag';
import router from './router'
import store from './store'
import session from "./session"
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartArea, faTable, faCheck, faGlobe, faInfoCircle, faDownload, faInfo, faChevronCircleDown, faChevronCircleUp, faCheckSquare, faChartBar, faHome, faQuestionCircle, faHandPointUp, faSearch, faHandHoldingHeart, faHandsHelping, faChessQueen, faArrowRight, faMapMarkedAlt, faList, faLifeRing, faChevronRight, faEnvelope, faBrain, faGlasses, faChartLine, faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BIcon, BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'

library.add(faChartArea, faTable, faCheck, faGlobe, faInfoCircle, faDownload, faInfo, faChevronCircleDown, faChevronCircleUp, faCheckSquare, faChartBar, faHome, faQuestionCircle, faHandPointUp, faSearch, faHandHoldingHeart, faHandsHelping, faChessQueen, faArrowRight, faMapMarkedAlt, faList, faLifeRing, faChevronRight, faEnvelope, faBrain, faGlasses, faChartLine, faEye, faEyeSlash, faSpinner)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)

Vue.config.productionTip = false
Vue.prototype.$http = session;

Vue.use(VueGtag, {
  config: { id: "G-3VPXK7KVP8" }
});

Vue.use(VueMeta);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
