<template>
  <div id="app" class="fluid">
    <a href="#dementie-information" tabindex="0" class="skip-link">Ga naar de hoofdinhoud</a>
    <div class="container-fluid">
      <b-alert variant="danger" show v-if="isMobile">
        Deze website is niet geschikt voor mobiele telefoons en tablets. U kunt de website wel bekijken, maar voor
        optimaal gebruik raden wij aan deze pagina te openen op uw computer.
      </b-alert>
    </div>

    <div id="header" class="row header justify-content-between align-items-center flex-nowrap" style="margin-right:0;">
      <div id="left-menu" class="col-auto">
        <div class="row align-items-center menu-left">
          <div class="col-md-auto home" style="margin-left: 3vw; width: 50px;">
            <button class="button3" type="button" @click="navigateToHome('Home')"
              :class="{ 'selected2': ($route.name || '').split('-')[0] === 'Home' }">
              <font-awesome-icon icon="home" size="2x" /><br>
            </button>
          </div>


          <div class="col" style="color: var(--color-2); margin-left: 3vw;">
            <div class="row">
              <div class="col-md-auto d-inline">
                <p class="d-inline">Welke zorg is verleend in </p>
                <label class="d-inline" style="margin-left: -10px" for="selecteerNetwerk"></label>
                <select id="selecteerNetwerk" aria-label="Selecteer het netwerkniveau" v-model="selected2"
                  style="color: var(--color-2)" size="sm" class="custom-dropdown custom-control" ref="firstCategory"
                  @change="setSelectWidthCategory()">
                  <option class="select-form-text" v-for="option in filteredLevelOptions" :value="option.value"
                    :key="option.value">{{ option.text }}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-auto">
                <div v-if="this.$store.state.selectedLevel !== 'landelijk'" :style="{ width: optionWidth }"
                  class="d-inline custom-spacing">
                  <label class="d-inline" style="margin-left: -10px" for="selecteerRegio"></label>
                  <select id="selecteerRegio" aria-label="Selecteer de regio" v-model="selected"
                    style="color: var(--color-2)" size="sm" class="custom-dropdown custom-control" ref="firstOption"
                    @change="setSelectWidthOption()">
                    <option class="select-form-text" v-for="option in options" :value="option" :key="option">{{ option }}
                    </option>
                  </select>
                </div>
                <p class="d-inline" v-if="shouldDisplaySelect && $route.name !== 'Nazorg-9'"> in het jaar </p>
                <div class="d-inline" v-if="shouldDisplaySelect && $route.name !== 'Nazorg-9'">
                  <label class="d-inline" style="margin-left: -5px" for="selecteerJaar"></label>
                  <select id="selecteerJaar" aria-label="Selecteer het jaar" v-model="selected3"
                    style="color: var(--color-2)" size="sm" class="custom-dropdown custom-control">
                    <option class="select-form-text" v-for="option in years" :value="option" :key="option">{{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="right-menu" class="col-auto right-menu" style="margin-right: 3.2vw">
        <div class="row align-items-center flex-nowrap menu-right">

          <div class="col">
            <button class="button3" type="button" @click="navigateToRoute('Overzicht')"
              :class="{ 'selected2': ($route.name || '').split('-')[0] === 'Overzicht' }">
              <font-awesome-icon icon="globe" size="2x" /><br>
              Overzicht
            </button>
          </div>

          <div class="col">
            <button class="button3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              :class="{ 'selected2': ['Niet', 'Diagnose', 'Nazorg', 'Leven'].indexOf(($route.name || '').split('-')[0]) >= 0 }">
              <font-awesome-icon icon="life-ring" size="2x" /><br>
              Levenspad
            </button>
            <ul class="dropdown-menu multi-level" aria-labelledby="dLabel">
              <li class="dropdown-submenu">
                <router-link class="dropdown-item" to="/Niet-Pluis/0">Niet-Pluis
                  <font-awesome-icon icon="chevron-right" size="sm" class="right" />
                </router-link>
                <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" to="/Niet-Pluis/7">Overig</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu">
                <router-link class="dropdown-item" to="/Diagnose/0">Diagnose
                  <font-awesome-icon icon="chevron-right" size="sm" class="right" />
                </router-link>
                <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" to="/Diagnose/9">Wmo/Wlz</router-link></li>
                  <li><router-link class="dropdown-item" to="/Diagnose/2">Wonen</router-link></li>
                  <li><router-link class="dropdown-item" to="/Diagnose/10">Huisarts</router-link></li>
                  <li><router-link class="dropdown-item" to="/Diagnose/7">Overig</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu">
                <router-link class="dropdown-item" to="/Leven-Met-Dementie/0">Leven met dementie
                  <font-awesome-icon icon="chevron-right" size="sm" class="right" />
                </router-link>
                <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" to="/Leven-Met-Dementie/1">Ziekenhuis</router-link></li>
                  <li><router-link class="dropdown-item" to="/Leven-Met-Dementie/8">Medicatie</router-link></li>
                  <li><router-link class="dropdown-item" to="/Leven-Met-Dementie/9">Wmo/Wlz</router-link></li>
                  <li><router-link class="dropdown-item" to="/Leven-Met-Dementie/2">Wonen</router-link></li>
                  <li><router-link class="dropdown-item" to="/Leven-Met-Dementie/3">Invulling van de dag</router-link>
                  </li>
                  <li><router-link class="dropdown-item" to="/Leven-Met-Dementie/6">Inkomen en Vermogen</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu">
                <router-link class="dropdown-item" to="/Nazorg/7">Stervensfase en Nazorg
                  <font-awesome-icon icon="chevron-right" size="sm" class="right" />
                </router-link>
                <ul class="dropdown-menu">
                  <li><router-link class="dropdown-item" to="/Nazorg/1">Zorg en Ondersteuning</router-link></li>
                  <li><router-link class="dropdown-item" to="/Nazorg/9">Wmo/Wlz</router-link></li>
                  <li><router-link class="dropdown-item" to="/Nazorg/2">Wonen</router-link></li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="col">
            <button class="button3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              :class="{ 'selected2': ['Factsheet', 'Mijn'].indexOf(($route.name || '').split('-')[0]) >= 0 }">
              <font-awesome-icon icon="list" size="2x" /><br>
              Thema's
            </button>
            <ul class="dropdown-menu">
              <li v-if="this.$store.state.selectedLevel !== 'landelijk'"><router-link class="dropdown-item"
                  to="/mijn-regio">Mijn regio</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/1">Thema Achtergrondkenmerken</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/2">Thema Langdurige Zorg</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/3">Thema Ziekenhuisopnames</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/4">Thema Maatschappelijke Ondersteuning</router-link>
              </li>
              <li><router-link class="dropdown-item" to="/Factsheet/5">Thema Medicatie</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/6">Thema Huisarts(enpost)</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/7">Thema Zorg door de tijd</router-link></li>
              <li><router-link class="dropdown-item" to="/Factsheet/8">Thema Mantelzorg</router-link></li>
            </ul>
          </div>

          <!-- <div class="col">
          <router-link to="/strategie" style="margin-right: 0px">
            <div class="button2 center" :class="{'selected2': ($route.name || '').split('-')[0] === 'Strategie'}">
              <font-awesome-icon icon="chess-queen" size="2x" />
              Strategie
            </div>
          </router-link>
        </div> -->

          <div class="col">
            <button class="button3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              :class="{ 'selected2': ($route.name || '').split('-')[0] === 'Zorgstandaard' }">
              <font-awesome-icon icon="check-square" size="2x" /><br>
              Zelfscan ZSD
            </button>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/login">Inlogportaal</router-link></li>
              <li><router-link class="dropdown-item" to="/zorgstandaard/resultaten-quickscan">Quickscan
                  resultaten</router-link></li>
              <li><router-link class="dropdown-item"
                  to="/zorgstandaard/netwerkcoordinator/resultaten-netwerkinkaart">Netwerken in Kaart Resultaten</router-link></li>
            </ul>
          </div>

          <!-- <div class="col">
                <router-link to="/login" style="margin-right: 0px">
                  <div class="button2 center" :class="{'selected2': ($route.name || '').split('-')[0] === 'Zorgstandaard'}">
                    <font-awesome-icon icon="check-square" size="2x" />
                    Zorgstandaard
                  </div>
                </router-link>
              </div>  -->

          <div class="col">
            <button class="button3" type="button" @click="navigateToRoute('Identificatie')"
              :class="{ 'selected2': ($route.name || '').split('-')[0] === 'Identificatie' }">
              <font-awesome-icon icon="chart-bar" size="2x" /><br>
              Identificatie
            </button>
          </div>

          <div class="col">
            <button class="button3" type="button" @click="navigateToRoute('About')"
              :class="{ 'selected2': ($route.name || '').split('-')[0] === 'About' }">
              <font-awesome-icon icon="question-circle" size="2x" /><br>
              Help
            </button>
          </div>


        </div>
      </div>
    </div>

    <div id="dashboard" class="row">
      <div class="col">
        <div class="dashboard-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      isMobile: false,
      optionWidth: 400,
      level_options: [
        {
          value: 'zorgkantoor', text: 'Zorgkantoor'
        },
        {
          value: 'dementienetwerk', text: 'Dementienetwerk'
        },
        {
          value: 'landelijk', text: 'Landelijk'
        }
      ],
      options: ["<Maak uw keuze>"],
      years: [2017, 2018, 2019, 2020, 2021],
      currentPathId: 0,
      optionsLoaded: false,
    }
  },
  created() {
    // initializing selected based on the initial selectedLevel
    const initialLevel = this.$store.state.selectedLevel;
    this.updateOptions(initialLevel).then(() => {
      if (initialLevel === 'landelijk') {
        this.selected = null;
      }
      this.setSelectWidthOption();
    });
  },
  watch: {
    // '$store.state.selectedLevel': function (level) {
    //   this.updateOptions(level)
    //   this.setSelectWidthOption()
    // },
    'selected3': function () {
      this.updateOptions(this.$store.state.selectedLevel)
    },
    '$store.state.selectedLevel': function (level) {
      this.updateOptions(level).then(() => {
        // resetting selected if 'landelijk' is selected
        if (level === 'landelijk') {
          this.selected = null;
        }
        this.setSelectWidthOption();
      });
    },
    '$route.name': function () {
      this.currentRouteNameEnd()
    }
    //'$store.state.'
  },
  mounted() {
    (async () => {
      try {
        await this.updateOptions(this.$store.state.selectedLevel)
        this.setSelectWidthOption()
        this.setSelectWidthCategory()

        this.checkViewportSize()
        window.addEventListener('resize', this.checkViewportSize)

        this.$nextTick(() => {
          this.loadOptionsAndSetWidth();
        });

      } catch (error) {
        console.log(error)
      }
    })()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkViewportSize)
  },
  methods: {
    checkViewportSize() {
      this.isMobile = window.innerWidth < 800 // adjust this threshold to the desired breakpoint
    },
    async loadOptionsAndSetWidth() {
      await this.updateOptions(this.$store.state.selectedLevel);
      this.setSelectWidthOption();
    },
    updateOptions(level) {
      return new Promise((resolve, reject) => {
        if (level === 'zorgkantoor') {
          this.$http.get('/api/zorgkantoor_totaal/').then(
            (response) => {
              this.options = ["<Maak uw keuze>", ...new Set(response.data.map(d => d.zorgkantoorregionaam))];
              this.optionsLoaded = true;
              resolve();
            }
          ).catch(reject);
        } else if (level === 'dementienetwerk') {
          this.$http.get('/api/dementienetwerk_totaal/').then(
            (response) => {
              this.options = ["<Maak uw keuze>", ...new Set(response.data.map(d => d.dementienetwerknaam))];
              const excludeListOld = [
                "Oost Achterhoek",
                "Sigma Nijkerk",
                "Noordoost-Overijssel",
                "West Achterhoek",
                "Bunnik-Wijk bij Duurstede",
                "Platform Nijmegen"
              ];

              const excludeListNew = [
                "Nijmegen",
                "Achterhoek",
                "Almere",
                "Zuid-Hollandse eilanden / Voorne-Putten Rozenburg",
                "Scherpenzeel"
              ];

              if (this.$store.state.selectedYear >= 2021) {
                this.options = this.options.filter(option => !excludeListOld.includes(option));
              } else {
                this.options = this.options.filter(option => !excludeListNew.includes(option));
              }

              this.options.sort();
              this.optionsLoaded = true;
              resolve();
            }
          ).catch(reject);
        } else {
          // For 'landelijk' level
          this.options = ["<Maak uw keuze>"];
          this.optionsLoaded = true;
          resolve();
        }
      });
    },

    currentRouteNameEnd() {
      if (((this.$route.name || '').split('-')[0] === 'Niet') ||
        ((this.$route.name || '').split('-')[0] === 'Nazorg') ||
        ((this.$route.name || '').split('-')[0] === 'Leven') ||
        ((this.$route.name || '').split('-')[0] === 'Diagnose')) {
        this.currentPathId = this.$route.name.split('-').pop()
      }
    },
    setSelectWidthCategory() {
      const select = this.$refs.firstCategory;
      const selectedOption = select.selectedOptions[0];
      const padding = 2;
      select.style.width = `${selectedOption.text.length + padding}ch`;
    },
    setSelectWidthOption() {
      if (this.optionsLoaded) {
        let padding = 0;
        const select = this.$refs.firstOption;
        const selectedOption = select.selectedOptions[0];

        const longLengthOptions = [
          'Westland Schieland Delfland', 'Zuid-Hollandse Eilanden', 'Barendrecht-Ridderkerk-Alb',
          'Stedelijke Rotterdam SKDR', 'Noord- en Oost-Flevoland',
          'Noordelijke Maasvallei', 'Zuid Hollandse Eilanden',
          'Bunnik-Wijk bij Duurstede', 'Capelle/Krimpen ad Ijssel']

        const middleLengthOptions = ['Eindhoven e.o.', 'Gelderse Vallei',
          'Lokaal Sigma-Nijkerk', 'Maastricht en Heuvelland', 'Noord Holland Noord', 'Noordoost-Overijssel',
          'Oss Uden Meierijstad', 'Platform Nijmegen', 'Steenwijkerland', 'UtrechtseHeuvelrug', 'Westelijk Utrecht', 'Zaanstreek Waterland', 'Zeeuwse Zorgschakels', 'Zutphen Lochem e.o.',
          'Zuidoost-Brabant', 'stad Utrecht', 'Noord-Holland Noord',
          'Zaanstreek/Waterland', 'IJssel-Vecht', 'Zuid Holland Noord']

        if (longLengthOptions.includes(selectedOption.value)) {
          padding = 1;
        }
        else if (selectedOption.value === 'Bommelerwaard/s-Hertogenb.') {
          padding = 1;
        }
        else if (selectedOption.value === 'Helmond/Peelgemeenten' || selectedOption.value === 'Amsterdam') {
          padding = 3;
        }
        else if (middleLengthOptions.includes(selectedOption.value)) {
          padding = -1;
        }
        else {
          padding = 2.2;
        }
        select.style.width = `${selectedOption.text.length + padding}ch`;
      }
      return false;
    },
    navigateToRoute(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push('/' + routeName);
      }
    },
    navigateToHome(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    }
  },
  computed: {
    shouldDisplaySelect() {
      return this.$store.state.selectedLevel !== 'landelijk' ||
        (this.$store.state.selectedLevel === 'landelijk' && this.$route.name === 'Factsheet-6') ||
        (this.$store.state.selectedLevel === 'landelijk' && this.$route.name === 'Diagnose-10');
    },
    selected: {
      get() {
        return this.$store.state.selectedRegion ? this.$store.state.selectedRegion : "<Maak uw keuze>";
      },
      set(newRegion) {
        if (newRegion === "<Maak uw keuze>") {
          // If the user selects the default option, set selectedRegion to null
          this.$store.commit('changeRegion', null);
        } else {
          this.$store.commit('changeRegion', newRegion);
        }
      }
    },
    selected2: {
      get() {
        return this.$store.state.selectedLevel || "<Maak uw keuze>";
      },
      set(newLevel) {
        if (newLevel === "<Maak uw keuze>") {
          // If the user selects the default option, set selectedLevel to null
          this.$store.commit('changeLevel', null);
        } else {
          // Set the new selected level
          this.$store.commit('changeLevel', newLevel);
        }
      }
    },
    selected3: {
      get() {
        return this.$store.state.selectedYear;
      },
      set(newYear) {
        this.$store.commit('changeYear', newYear);
      }

    },
    currentRoutePath() {
      return this.$route.path.split('/')[1];
    },
    filteredLevelOptions() {
      const routeName = this.$route.name || '';
      // if the current route is 'Mijn-Regio' or 'Overzicht'
      if (routeName === 'Mijn-Regio' || routeName === 'Nazorg-9') {
        // filtering the level_options array to remove the 'Landelijk' option
        return this.level_options.filter(option => option.value !== 'landelijk');
      } else {
        return this.level_options;
      }
    }
  }
}


</script>

<style>
.app {
  overflow: hidden;
}

p {
  font-size: 1em;
  line-height: 1.5;
}



.dashboard {
  overflow: hidden;
}

.right {
  float: right;
  margin-right: -20px;
  margin-top: 5px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-menu {
  margin-top: -5px;
}

.dropdown-submenu>.dropdown-menu {
  left: 100%;
  /* -6px gives dropdown-menu's padding+border */
  top: -0px;
}

.dropdown-submenu:hover>.dropdown-menu,
.dropdown-submenu>a:focus+.dropdown-menu {
  /* :focus support is incomplete - pressing Tab sets focus to submenu, but that immediately hides submenu */
  display: block;
}


.dropdown-item {
  margin-left: -20px;
  margin-right: 40px;
}

.dashboard-content {
  margin-top: 100px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.down {
  margin-top: 20px !important;
}

.graphtext {
  height: 40px;
}

:root {
  --color-1: #144273;
  /*Dark blue*/
  --color-2: #ffffff;
  /*White*/
  --color-3: #EAE7E3;
  /*sand*/
  --color-4: #a1b3c7;
  /*light blue*/
  --color-5: #3C8DBC;
  /*bright blue*/
  --color-6: #d3d3d3;
  /*light grey*/
  --color-7: #B2C0D0;
  /*blue*/
  --color-8: #f5f9fd;
  /* pale blue*/
  --color-9: #e9ecef;
  /*light grey-white*/
  --color-10: #545454;
  /*dark grey*/
  --color-11: #00796b;
  /*dark green*/
  --color-12: #1f2a44;
  /*dark blue*/
  --color-13: #e3e7ed;
  /*lavender*/
  --color-14: #337f64;
  /*seagreen*/
  --color-15: #3B3A3A;
  /*khaki*/
  --color-16: #D51204;
  /*red*/
}

@font-face {
  font-family: "Gotham";
  src: url('./assets/fonts/Gotham-Book.woff') format('woff'),
    url('./assets/fonts/Gotham-Book.woff2') format('woff2'),
    url('./assets/fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
}


a {
  color: inherit;
  text-decoration: none !important;
  margin-right: 40px;
}

#app {
  font-family: system-ui, sans-serif;
  width: 100%;
}

.about {
  color: var(--color-1);
}

html,
body {
  height: 100vh;
}

body {
  margin: 0;
  background-color: var(--color-2);
}

.app {
  background-color: var(--color-2);
}

.header {
  background-color: var(--color-1);
  height: 8vh;
  position: fixed;
  left:0;
  top:0;
  width:100vw;
  z-index: 200;
}

.view {
  background-color: var(--color-2);
  min-width: 100vh;
  width: 100%;
  padding-top: 15px;
}

.view.container-fluid {
  height: calc(100vh - 90px) !important;
  overflow-y: auto;
}

.radio-div {
  width: 17ch;
  background-color: var(--color-1);
  color: white;
  border-color: var(--color-7);
}

.dropdown {
  background-color: var(--color-1);
  color: white;
  border-color: var(--color-7);
}

.home:focus {
  outline: green;
}

.home-icon {
  margin-top: 2vh;
  transition: 0.3s ease-in-out;
  font-size: 15px;
}

.home-icon:focus,
.home-icon:hover {
  outline: none !important;
  box-shadow: none;
  font-size: 20px;
}

.home-icon:focus .fa-icon {
  font-size: 5px;
}


.button {
  color: var(--color-2);
}

.button2 {
  color: var(--color-7);
  font-size: 13px;
  transition: 0.3s ease-in-out;
}

.button3 {
  background-color: var(--color-1);
  color: var(--color-7);
  font-size: 13px;
  border: None;
  transition: 0.3s ease-in-out;
}


.button:hover,
.button:focus {
  outline: none !important;
  box-shadow: none;
}

.button3:hover,
.button2:hover {
  cursor: pointer;
  color: var(--color-2) !important;
}

.button3:focus,
.button2:focus {
  outline: none !important;
  box-shadow: none;
  cursor: pointer;
  color: var(--color-2) !important;
  font-size: 15px;
}

.selected2 {
  color: white;
}

li {
  margin-left: 20px !important;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
}

label {
  padding-left: 1ch;
}


.custom-control {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  font-weight: bold;
  font-size: 18px;
  font-family: inherit !important;
  transition: 0.3s ease-in-out;
}

.custom-control:focus {
  outline: none !important;
  border: 2px #e8f0fe solid !important;
  border-radius: 15px;
}

.custom-dropdown {
  background-color: transparent;
  color: var(--color-1);
  font-weight: bold;
  width: auto;
}

.select-form-text {
  font-weight: bold;
  color: var(--color-2) !important;
  background: var(--color-1) !important;
}

.custom-spacing {
  margin-left: -4px;
}

@media only screen and (max-width: 900px) {
  p {
    font-size: 12px;
  }

  .custom-control {
    font-size: 12px;
  }

  .header {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center ! important;
  }

  .menu-left {
    display: flex;
    flex-direction: column !important;
  }

  .button {
    font-size: 10px;
  }

  button:focus {
    outline: 2px solid var(--color-2);
    /* Customize this to your desired focus style */
    /* Add any other focus styles you want */
  }

  .right-menu {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }

  .menu-right {
    margin-top: 1rem !important;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center ! important;
  }

  .button2 {
    font-size: 10px;
  }

  .button3 {
    font-size: 10px;
  }
}

.skip-link {
  background: #319795;
  color: #fff;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-link:focus,
.skip-link:hover {
  transform: translateY(0%);
  outline: none !important;
  box-shadow: none;
  color: var(--color-2);
}
</style>