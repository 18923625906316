<template>
  <div class="home">
    <!-- <a href="#dementie-information" tabindex="0" class="skip-link">Ga naar de hoofdinhoud</a> -->
    <section class="bgimage">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h1>Welkom in het register DementieZorg en -Ondersteuning (DZO)</h1>
          </div>
        </div>
      </div>
    </section>


    <div id="dementie-information">
      <section class="dementie-information" style="background: #e2e7ed">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="w-50 dementie-information">
            <p> Het ‘Register Dementiezorg en Ondersteuning’ geeft inzicht in het gebruik van zorg en ondersteuning door
              mensen met dementie en hun naasten. Beleidsmakers, bestuurders, coördinatoren van dementienetwerken en
              andere gebruikers kunnen de informatie in dit dashboard gebruiken om met zorgpartners in gesprek te gaan.
              Krijgen mensen de juiste zorg? En hoe kunnen we nog betere zorg leveren, op het juiste moment en op de
              juiste plek? </p>

            <div class="row">
              <div class="d-flex align-items-center justify-content-center">
                <p class="" style="margin-right: 15px">
                  Klik hier om te starten, of scroll naar beneden voor meer informatie en toelichting
                </p>
              </div>
            </div>

            <div class="row" style="margin-bottom: 1rem;">
              <div class="d-flex align-items-center justify-content-center">
                <router-link aria-label="Een knop om de overzichtspagina te bezoeken" value="naarOverzichtsPagina"
                  name="DownloadenKnop" to="/overzicht" class="btn custom-button position-relative"
                  style="text-align:left; " aria-pressed="false" type="button">Overzicht
                  <span class="position-absolute"></span>
                </router-link>
              </div>
            </div>

            <div class="row">
              <div class="d-flex align-items-center justify-content-center">
                <p class="" style="margin-right: 15px">
                  Wil je een update ontvangen als er nieuwe data is toegevoegd, vul dan hier uw emailadres in.
                </p>
              </div>
            </div>

            <div class="row" style="margin-bottom: 1rem;">
              <div class="d-flex justify-content-center">
                <button @click="showAddEmailModal" class="btn custom-button">Emailadres invullen</button>
                <add-email-modal v-show="isAddEmailModalVisible" @close="closeModal"/>
              </div>
            </div>

            <div class="row">
              <div class="d-flex align-items-center justify-content-center">
                <p> Veel dementiezorg is regionaal georganiseerd om zo goed mogelijk te kunnen voldoen aan de wensen en
                  behoeften van mensen met dementie en hun naasten. Daarom presenteren wij de inzichten van dit dashboard
                  op regionaal niveau, om daarmee een beeld te schetsen over de regionale verschillen die bestaan over
                  aanbod en gebruik van zorg. Het doel van dit dashboard en register is drieledig:
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="dementie-information" style="background: #A0B0C4">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="container" style="background: #A0B0C4">
            <div class="row">
              <section class="mx-auto my-5 col-sm-4" style="max-width: 23rem;">
                <div class="card testimonial-card mt-2 mb-3 shadow home-card">
                  <div class="card-up card-banner"></div>
                  <div class="avatar mx-auto white">
                    <img :src="identificatie_icon" class="rounded-circle img-fluid" alt="">
                  </div>
                  <div class="card-body text-center">
                    <h4 class="card-title font-weight-bold">Identificatie</h4>
                    <hr>
                    <p><i class="fas fa-quote-left"></i> Hoeveel mensen ontvangen de diagnose dementie en waar in het
                      zorgpad wordt de diagnose gesteld. We maken hiervoor gebruik van verschillende bronnen over
                      bijvoorbeeld ziekenhuisbezoeken, medicijngebruik en opnames in verpleeghuizen.</p>
                  </div>
                </div>
              </section>

              <section class="mx-auto my-5 col-sm-4" style="max-width: 23rem;">
                <div class="card testimonial-card mt-2 mb-3 shadow home-card">
                  <div class="card-up card-banner"></div>
                  <div class="avatar mx-auto white">
                    <img :src="inzichten_icon" class="rounded-circle img-fluid" alt="">
                  </div>
                  <div class="card-body text-center">
                    <h4 class="card-title font-weight-bold">Inzichten</h4>
                    <hr>
                    <p><i class="fas fa-quote-left"></i> Hoe gaat het met de mensen met dementie? Ontvangen zij de juiste
                      zorg? En welke verbanden kan je zien tussen bijvoorbeeld langdurige zorg en ondersteuning thuis? We
                      verzamelen gegevens over het zorggebruik en welzijn van de mensen met dementie.</p>
                  </div>
                </div>
              </section>

              <section class="mx-auto my-5 col-sm-4" style="max-width: 23rem;">
                <div class="card testimonial-card mt-2 mb-3 shadow home-card">
                  <div class="card-up card-banner"></div>
                  <div class="avatar mx-auto white">
                    <img :src="verbeteren_icon" class="rounded-circle img-fluid" alt="">
                  </div>
                  <div class="card-body text-center">
                    <h4 class="card-title font-weight-bold">Verbeteren</h4>
                    <hr>
                    <p><i class="fas fa-quote-left"></i> Wat zijn de regionale verschillen in zorggebruik, ondersteuning
                      en welzijn? Deze verschillen kunnen een startpunt vormen voor regionale beleidsmakers en bestuurders
                      voor verder onderzoek. Dit geeft ruimte om potentiële verbeteringen te identificeren en het gesprek
                      aan te gaan met vertegenwoordigers van andere regio’s om gezamenlijk te leren van elkaar.</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>


      <section class="dementie-information" style="background: #e2e7ed">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="w-50 dementie-small-section">
            <p> Voor de leesbaarheid van het dashboard raden wij aan deze pagina op uw gehele scherm te openen. De meeste
              data zijn geleverd door het Centraal Bureau voor de Statistiek. Indien dat niet het geval is, is dat vermeld
              bij het betreffende diagram. Dit register is ontwikkeld door Amsterdam Data Collective en Erasmus School of
              Health Policy & Management, in opdracht van het ministerie van Volksgezondheid, Welzijn en Sport. Er is
              daarnaast nauw samengewerkt met onder meer Dementie Netwerk Nederland en Alzheimer Nederland.</p>
          </div>
        </div>
      </section>


      <section class="dementie-information" style="background: #144273">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="w-75 navbar-information">
            <h2 class="section-2-title">Hoe gebruikt u dit dashboard?</h2>

            <div class="row justify-content-center">
              <div class="col-7 align-self-center" style="margin-bottom: 2vh;">
                <img :src="navbar" class="navbar-img" alt="">
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 align-items-center">
                <div class="row justify-content-center">
                  <div class="col-4">
                    <div class="icon-wrapper">
                      <img :src="one" class="numbers-img" alt="">
                      <p class="explanation-navbar-text">
                        Hier selecteert u welk niveau u wilt zien: landelijk, per zorgkantoor of per dementienetwerk</p>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="icon-wrapper">
                      <img :src="two" class="numbers-img" alt="">
                      <p class="explanation-navbar-text">
                        Hier selecteert u in welke regio u geïnteresseerd bent. Deze regio wordt in alle grafieken
                        zichtbaar gemaakt
                      </p>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="icon-wrapper">
                      <img :src="three" class="numbers-img" alt="">
                      <p class="explanation-navbar-text">
                        Hier selecteert u van welk jaar u de resultaten wilt zien. Door vertragingen in de datakwaliteit
                        is het meest recente jaar in dit dashboard altijd ongeveer twee jaar geleden, en zijn sommige
                        databronnen mogelijk nog minder recent
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="menu-information" style="background: #e2e7ed">
        <div class="container">
          <div class="row justify-content-center column-custom-space">
            <div class="col">
              <div class="icon-wrapper">
                <div class="dashboard-img">
                  <font-awesome-icon icon="globe" size="4x" />
                </div>
                <p class="icon-text">Overzicht</p>
                <div class="menu-text">
                  <p class="menu-txt">Hier vindt u een geografische weergave van de variabelen.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="icon-wrapper">
                <div class="dashboard-img">
                  <img :src="lifeRing" class="dashboard-logos" alt="">
                </div>
                <p class="icon-text">Levenspad</p>
                <div class="menu-text">
                  <p class="menu-txt">Hieronder staan de verschillende fases van het levenspad. Deze kunnen ook in de balk
                    bovenaan de pagina worden geselecteerd bij het bijbehorende icoontje.</p>
                </div>
              </div>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col">
              <div class="icon-wrapper">
                <div class="dashboard-img">
                  <img :src="list" class="dashboard-logos" alt="">
                </div>
                <p class="icon-text">Thema's</p>
                <div class="menu-text">
                  <p class="menu-txt">Hieronder staan de verschillende thema's. Deze kunnen ook in de balk bovenaan de
                    pagina worden geselecteerd bij het bijbehorende icoontje. De meeste grafieken zijn zowel via de
                    levenspadfases te vinden als via de factsheets.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="icon-wrapper">
                <div class="dashboard-img">
                  <font-awesome-icon icon="check-square" size="4x" class="dashboard-logos" />
                </div>
                <p class="icon-text">Zelfscan ZSD</p>
                <div class="menu-text">
                  <p class="menu-txt">Hier kunt u de vragenlijst van de Zorgstandaard Dementie invullen als u
                    netwerkcoördinator of ketenpartner bent. Daarnaast vindt u hier de resultaten van netwerken op de
                    vragenlijst van de Zorgstandaard dementie.</p>
                </div>
              </div>
            </div>

          </div>
          <div class="row justify-content-center column-custom-space">
            <div class="col">
              <div class="icon-wrapper">
                <div class="dashboard-img">
                  <img :src="charBar" class="dashboard-logos" alt="">
                </div>
                <p class="icon-text">Identificatie</p>
                <div class="menu-text">
                  <p class="menu-txt">Hier kunt u informatie vinden over het aantal mensen met dementie, hoe dit zich per
                    regio verhoudt ten opzichte van het landelijke gemiddelde, en welke groei we tot 2050 verwachten.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="icon-wrapper">
                <div class="dashboard-img">
                  <img :src="questionCircle" class="dashboard-logos" alt="">
                </div>
                <p class="icon-text">Help</p>
                <div class="menu-text">
                  <p class="menu-txt">Hier kunt u meer informatie vinden over de verschillende databronnen. Daarnaast
                    worden de veelgestelde vragen op deze pagina beantwoord.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="dementie-information" style="background: #5D7FAC">
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="w-75 navbar-information">
            <h1 class="section-2-title">Eerste resultaten in infographic</h1>
            <div class="row justify-content-between">
              <div class="col-8 align-self-center">
                <img :src="infographic"
                  alt="De infographic toont administratieve gegevens met betrekking tot het gebruik van zorgdiensten door personen gediagnosticeerd met dementie in verschillende stadia van de aandoening."
                  class="infographic-img" />
              </div>

              <div class="col-3 align-self-center justify-content-center">
                <div class="row">
                  <div class="col-sm explanation-navbar">
                    <p class="explanation-navbar-text">In deze infographic kunt u een samenvatting vinden van enkele
                      inzichten uit het Register Dementiezorg</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm explanation-navbar align-self-center">
                    <template>
                      <div>
                        <a tabindex="-1" href="/files/Infographic-Register.svg" download>
                          <button aria-label="Downloaden knop" value="Downloaden" name="DownloadenKnop"
                            class="custom-spacing-top button-print" aria-pressed="false" type="button">Downloaden</button>
                        </a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import dementiebanner from '@/assets/dementie_banner.jpg'
import identificatie_icon from '@/assets/identificatie_icon.svg'
import inzichten_icon from '@/assets/inzichten_icon.svg'
import navbar from '@/assets/navbar.svg'
import one from '@/assets/one.svg'
import two from '@/assets/two.svg'
import three from '@/assets/three.svg'
import list from '@/assets/list.svg'
import questionCircle from '@/assets/question-circle.svg'
import lifeRing from '@/assets/life-ring.svg'
import charBar from '@/assets/char-bar.svg'
import verbeteren_icon from '@/assets/verbeteren_icon.svg'

import infographic from '@/assets/Infographic-Register.svg'
import html2pdf from 'html2pdf.js';
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';
import AddEmailModal from '../components/AddEmailModal.vue'


export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Dementie in kaart',
  },
  components: { Footer, AddEmailModal},
  data() {
    return {
      infographic: infographic,
      isAddEmailModalVisible: false,
      dementiebanner: dementiebanner,
      identificatie_icon: identificatie_icon,
      inzichten_icon: inzichten_icon,
      verbeteren_icon: verbeteren_icon,
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      charBar: charBar,
      list: list,
      lifeRing: lifeRing,
      questionCircle: questionCircle,
      one: one,
      two: two,
      three: three,
      navbar: navbar,
      selected: null,
      selected2: null,
      selected3: null,
      level_options: [
        { value: null, text: 'Zorgkantoor' },
        { value: 'a', text: 'Dementienetwerk' }
      ],
      options: [
        { value: null, text: 'Geselecteerde regio' },
        { value: 'a', text: 'Amsterdam' },
        { value: 'b', text: 'Rotterdam' }
      ],
      options2: [
        { value: null, text: 'Geselecteerd jaar' },
        { value: 'c', text: '2019' },
        { value: 'd', text: '2020' }
      ]
    }
  },
  methods: {
    downloadPDF() {
      const svgContainer = this.$refs.svgContainer;
      const svgContent = svgContainer.innerHTML;
      const options = {
        margin: 1,
        filename: 'infographic.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
      };
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svg.setAttribute('version', '1.1');
      const width = svgContainer.offsetWidth;
      const height = svgContainer.offsetHeight;
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);
      svg.innerHTML = svgContent;
      html2pdf().set(options).from(svg.outerHTML).save();
    },
    showAddEmailModal() {
      this.isAddEmailModalVisible = true;
    },
    closeModal() {
      this.isAddEmailModalVisible = false;
    },
  }
}
</script>

<style scoped>
.bgimage h1 {
  color: var(--color-15);
  font-weight: bold;
  margin-top: 80px;
  margin-left: 50px;
  max-width: 800px;
}

.bgimage {
  width: 100%;
  height: 500px;
  background: url('https://images.unsplash.com/photo-1465493251445-c6af8fc40b7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2074&q=80');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}


.dementie-information {
  padding: 30px 0 30px 0;
}

.navbar-information {
  padding: 50px 0 50px 0;
}

.menu-information {
  padding: 150px 0 150px 0;
}

.dementie-small-section {
  padding: 50px 0 50px 0;
  margin: 80px 0 80px 0;
}

.footer {
  padding: 150px 0 150px 0;
}

p {
  font-weight: lighter;
}

.home {
  font-size: 1em;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  margin-bottom: 3rem;
}

.card {
  border: none;
}



.testimonial-card .card-up {
  height: 120px;
  overflow: hidden;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card-banner {
  background: var(--color-12) !important;
}

.testimonial-card .avatar {
  width: 120px;
  margin-top: -60px;
  overflow: hidden;
  border: 5px solid #fff;
  border-radius: 50%;
}

.navbar-img {
  width: 100%;
}

.numbers-img {
  width: 30px;
  height: 30px;
}

.explanation-navbar {
  display: flex;
  align-items: center;
}

.explanation-navbar-text {
  text-align: center;
  margin-top: 1vh;
  color: var(--color-2);
}

.section-2-title {
  color: var(--color-2);
  font-weight: bold;
  width: 500px;
  font-size: 3em;
}

.contact-text {
  width: 500px;
  line-height: 1.6;
}


.dashboard-img {
  display: inline-block;
  border-radius: 100%;
  border: 2px solid #3e3a39;
  padding: 2em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
}

.icon-text {
  text-align: center;
  margin-top: 2rem;
  font-weight: bold;
  color: #3e3a39;
}

.menu-text {
  text-align: center;
}

.menu-txt {
  margin: 20px 0 10px 0;
  font-weight: lighter;
}

.footer-contact {
  color: var(--color-2);
}

.footer-title {
  color: var(--color-2);
}

.contact-icon {
  font-size: 2em;
  margin-right: 30px;
}

.contact-title {
  color: var(--color-2);
  font-weight: bold;
  width: 500px;
  font-size: 3em;
}

.custom-space {
  margin-top: 3rem;
}


ul {
  list-style-type: none;
  list-style: none;
  margin: 1rem;
  padding: 0;
}

li {
  font-weight: lighter;
  color: var(--color-2);
}


.footer-span {
  display: block;
  width: 100%;
  border-top: 1px solid #ccc
}

.item {
  color: var(--color-2);
}


.item:hover {
  cursor: pointer;
  color: var(--color-2) !important;
}

.infographic-img {
  width: 100%;
  border-radius: 10px;
}


.button-print {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: var(--color-12);
  border: none;
  outline: none;
  font-weight: bold;
  border-radius: 20px;
}


.button-print:hover {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-12);
  background: var(--color-2);
  border: none;
  outline: none;
}

.button-print:focus {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-12);
  background: var(--color-2);
  border: 2px var(--color-1) solid !important;
  outline: none;
}


.center {
  text-align: left;
  padding: 20px;
}

.column-custom-space {
  margin-bottom: 15px;
}

.custom-button {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: #403932;
  border: none;
  outline: none;
  font-weight: bold;
  border-radius: 20px;
}

.custom-button:hover {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-2);
  background: var(--color-1);
  border: none;
  outline: none;
}

.custom-button:focus {
  text-align: left;
  padding: 10px 20px 10px 20px;
  color: var(--color-1);
  background: var(--color-2);
  border: 2px var(--color-1) solid !important;
  outline: none;
}


.footer-logos {
  margin-top: 100px;
}

.skip-link {
  background: #319795;
  color: #fff;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-link:focus {
  transform: translateY(0%);
}


@media only screen and (min-width: 1000px) {
  .home-card {
    height: 600px;
  }
}

@media only screen and (max-width: 1000px) {
  .dementie-information .row {
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .testimonial-card {
    width: 300px;
  }

  .testimonial-card .avatar {
    width: 50px;
    overflow: hidden;
  }

  .testimonial-card .card-body {
    width: 300px;
    overflow: hidden;
  }

  .dashboard-img {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }

  .dashboard-logos {
    width: 30px;
    height: 30px;
  }
}
</style>
