import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'ZSD-Login',
    component: () => import('../views/ZSD-Login.vue')
  },
  {
    path: '/reset/:uid(.*)',
    name: 'ZSD-Reset-Password',
    component: () => import('../views/ZSD-Reset-Password.vue')
  },
  {
    path: '/overzicht',
    name: 'Overzicht',
    component: () => import('../views/Overzicht.vue')
  },
  {
    path: '/themas',
    name: 'Themas',
    component: () => import('../views/Themas.vue')
  },
  {
    path: '/factsheet/1',
    name: 'Factsheet-1',
    component: () => import('../views/Factsheet1.vue')
  },
  {
    path: '/factsheet/2',
    name: 'Factsheet-2',
    component: () => import('../views/Factsheet2.vue')
  },
  {
    path: '/factsheet/3',
    name: 'Factsheet-3',
    component: () => import('../views/Factsheet3.vue')
  },
  {
    path: '/factsheet/4',
    name: 'Factsheet-4',
    component: () => import('../views/Factsheet4.vue')
  },
  {
    path: '/factsheet/5',
    name: 'Factsheet-5',
    component: () => import('../views/Factsheet5.vue')
  },
  {
    path: '/factsheet/6',
    name: 'Factsheet-6',
    component: () => import('../views/Factsheet6.vue')
  },
  {
    path: '/factsheet/7',
    name: 'Factsheet-7',
    component: () => import('../views/USZorggebruik.vue')
  },
  {
    path: '/factsheet/8',
    name: 'Factsheet-8',
    component: () => import('../views/USMantelzorg.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/mijn-regio',
    name: 'Mijn-Regio',
    component: () => import('../views/MijnRegio.vue')
  },
  {
    path: '/zorgstandaard/respondent/details',
    name: 'ZSD-Respondent-Details',
    component: () => import('../views/ZSD-Respondent-Details.vue')
  },
  {
    path: '/zorgstandaard/netwerkanalyse',
    name: 'ZSD-Respondent-Netwerkanalyse',
    component: () => import('../views/ZSD-Respondent-Netwerkanalyse.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator',
    name: 'ZSD-NC',
    component: () => import('../views/ZSD-NC.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator/resultaten-netwerkanalyse',
    name: 'ZSD-NC-Resultaten-Netwerkanalyse',
    component: () => import('../views/ZSD-NC-Resultaten-Netwerkanalyse.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator/quickscan',
    name: 'ZSD-NC-Quickscan',
    component: () => import('../views/ZSD-NC-Quickscan.vue')
  },
  {
    path: '/zorgstandaard/resultaten-quickscan',
    name: 'ZSD-NC-Resultaten-Quickscan',
    component: () => import('../views/ZSD-NC-Resultaten-Quickscan.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator/resultaten-quickscan-over-tijd',
    name: 'ZSD-NC-Resultaten-Quickscan-Over-Tijd',
    component: () => import('../views/ZSD-NC-Resultaten-Quickscan-Over-Tijd.vue')
  },

  {
    path: '/zorgstandaard/netwerkcoordinator/netwerkinkaart',
    name: 'ZSD-NC-netwerkinkaart-vragen',
    component: () => import('../views/ZSD-NC-NetwerkInKaart.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator/resultaten-netwerkinkaart',
    name: 'ZSD-NC-netwerkinkaart-resultaten',
    component: () => import('../views/ZSD-NC-Resultaten-NetwerkInKaart.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator/resultaten-netwerkinkaartpers',
    name: 'ZSD-NC-netwerkinkaart-resultatenpers',
    component: () => import('../views/ZSD-NC-Resultaten-NetwerkInKaartPers.vue')
  },
  {
    path: '/zorgstandaard/netwerkcoordinator/gebruikersbeheer',
    name: 'ZSD-Gebruikersbeheer',
    component: () => import('../views/ZSD-Gebruikersbeheer.vue')
  },
  {
    path: '/identificatie',
    name: 'Identificatie',
    component: () => import('../views/Identificatie.vue')
  },
  {
    path: '/levenspad',
    name: 'Levenspad',
    component: () => import('../views/Levenspad.vue')
  },
  {
    path: '/niet-pluis/0',
    name: 'Niet-Pluis-0',
    component: () => import('../views/Niet-Pluis-overzicht.vue')
  },
  {
    path: '/niet-pluis/7',
    name: 'Niet-Pluis-7',
    component: () => import('../views/Niet-Pluis-anders.vue')
  },
  {
    path: '/diagnose/0',
    name: 'Diagnose-0',
    component: () => import('../views/Diagnose-overzicht.vue')
  },
  {
    path: '/diagnose/2',
    name: 'Diagnose-2',
    component: () => import('../views/Diagnose-wonen.vue')
  },
  {
    path: '/diagnose/7',
    name: 'Diagnose-7',
    component: () => import('../views/Diagnose-anders.vue')
  },
  {
    path: '/diagnose/9',
    name: 'Diagnose-9',
    component: () => import('../views/Diagnose-wmowlz.vue')
  },
  {
    path: '/diagnose/10',
    name: 'Diagnose-10',
    component: () => import('../views/Diagnose-huisarts.vue')
  },
  {
    path: '/leven-met-dementie/0',
    name: 'Leven-met-Dementie-0',
    component: () => import('../views/Leven-met-Dementie-overzicht.vue')
  },
  {
    path: '/leven-met-dementie/1',
    name: 'Leven-met-Dementie-1',
    component: () => import('../views/Leven-met-Dementie-zorg.vue')
  },
  {
    path: '/leven-met-dementie/2',
    name: 'Leven-met-Dementie-2',
    component: () => import('../views/Leven-met-Dementie-wonen.vue')
  },
  {
    path: '/leven-met-dementie/3',
    name: 'Leven-met-Dementie-3',
    component: () => import('../views/Leven-met-Dementie-dag.vue')
  },
  {
    path: '/leven-met-dementie/6',
    name: 'Leven-met-Dementie-6',
    component: () => import('../views/Leven-met-Dementie-vermogen.vue')
  },
  {
    path: '/leven-met-dementie/8',
    name: 'Leven-met-Dementie-8',
    component: () => import('../views/Leven-met-Dementie-medicatie.vue')
  },
  {
    path: '/leven-met-dementie/9',
    name: 'Leven-met-Dementie-9',
    component: () => import('../views/Leven-met-Dementie-wmowlz.vue')
  },
  {
    path: '/nazorg/0',
    name: 'Nazorg-0',
    component: () => import('../views/Nazorg-overzicht.vue')
  },
  {
    path: '/nazorg/1',
    name: 'Nazorg-1',
    component: () => import('../views/Nazorg-zorg.vue')
  },
  {
    path: '/nazorg/2',
    name: 'Nazorg-2',
    component: () => import('../views/Nazorg-wonen.vue')
  },
  {
    path: '/nazorg/9',
    name: 'Nazorg-9',
    component: () => import('../views/Nazorg-wmowlz.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
