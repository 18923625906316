<template>
  <div class="modal-overlay">
    <div class="modal">
      <img class="close-img" src="../assets/close-icon.png" @click="close"/>
      <h6>Emailadres toevoegen</h6>
      <div v-on:keyup.enter="addEmail()">
        <input class="form-control input" :class="{ 'is-invalid': $v.email.$error}" v-model="email" placeholder="Email" @click="showSucceeded=false, showFailure=false, errorMessage = null" :disabled="loading"/>
        <div v-if="$v.email.$error" class="invalid-feedback">
          <span v-if="!$v.email.required">Email is vereist</span>
        </div>
      </div>
      <div>
        <button class="btn btn-secondary" @click="addEmail()" :disabled=" loading || showFailure || showFailureNetwerk">Opslaan</button>
      </div>
      <label v-if="showSucceeded">{{ errorMessage }}</label>
      <label v-if="showFailure" class="failure">{{ errorMessage }}</label>
      <font-awesome-icon v-if="loading" class="fa" icon="spinner" spin />
    </div>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddEmailModal',
  data () {
    return {
      email: "",
      errorMessage: null,
      showSucceeded: false,
      showFailure: false,
      loading: false
    }
  },
  validations: {
    email: { required },
  },
  methods: {
    close() {
      this.$emit('close');
      this.showSucceeded = false;
      this.showFailure = false;
      this.errorMessage = null;
      this.$v.$reset()
    },
    addEmail () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if(this.email){
          this.loading = true
          const requestBody = {
            email : this.email
          }
          this.$http.post(`/api/emaildataupdates/add_email/`, requestBody).then(
            (response) => {
              this.email="";
              this.loading = false;
              this.showSucceeded=true;
              this.errorMessage = response.data.message;
              this.$v.$reset();
            },
            (error) => {
              this.loading = false;
              this.showFailure=true;
              this.errorMessage = error.response.data.message;  
            }
          )
        }
      }
    },
  }
}
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 320px;
  width: 570px;
  margin-top: 10%;
  margin-left: 35%;
  padding: 10px 60px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.close-img {
  margin: 0 0 0 470px;
  width: 25px;  
  cursor: pointer;
}

.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 15px;
  width: 130px;
  max-width: 300px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 30px;
  padding-top: 20px;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #ac003e;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 16px;
  margin-top: 50px;
}

.fa {
  margin-top: 10px;
  align-self: center;
}

.custom-control {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    font-weight: bold;
    font-size: 18px;
    font-family: inherit !important;
    
}

.custom-control:focus {
    outline: none !important;

}

.custom-dropdown {
  background-color: transparent;
  color: var(--color-1);
  font-weight: bold;
  width: auto;
}


.select-form-text {
  font-weight: bold;
  color: var(--color-2) !important;
  background: var(--color-1) !important;
}

.failure{
  color: red;
}
</style>